const VueNews = {
  data() {
    return {
      articleStore: {
        data: [],
        count: 0,
        loop: 0,
      },
    };
  },
  methods: {
    getArchiveJson(start, count, callback = undefined, all = false) {
      this.articleStore.count = count;
      if(this.articleStore.count > 100){
        count = 100;
        this.articleStore.loop++;
      }
      $.ajax({
        url: `https://www.sonymusic.co.jp/json/v2/artist/${_artistFolder}/information/start/${start}/count/${count}`,
        type: 'GET',
        dataType: 'jsonp',
        scriptCharset: 'utf-8',
        jsonpCallback: 'callback',
        crossDomain: true,
      }).then(
        data => {
          if(this.articleStore.loop > 0){
            if(this.articleStore.count > 100){
              this.articleStore.count = this.articleStore.count - 100;
              this.articleStore.data = this.articleStore.data.concat(data.items);
              this.getArchiveJson(this.articleStore.loop * 100, this.articleStore.count, callback, all);
            }else{
              this.state.loop = 0;
              this.articleStore.data = this.articleStore.data.concat(data.items);
              if(typeof callback === 'function'){
                callback(this.articleStore.data);
              }
            }
          }else{
            if(all){
              this.articleStore.data = this.articleStore.data.concat(data.items);
              callback(this.articleStore.data);
            }else{
              if(typeof callback === 'function'){
                callback(data);
              }
            }
          }
        },
        error => {
          console.error(error);
        },
      );

    },
    getArchiveDetailJson(id, callback = undefined) {
      $.ajax({
        url: `https://www.sonymusic.co.jp/json/v2/artist/${_artistFolder}/information/${id}`,
        type: 'GET',
        dataType: 'jsonp',
        scriptCharset: 'utf-8',
        jsonpCallback: 'callback',
        crossDomain: true,
      }).then(
        data => {
          if(typeof callback === 'function'){
            callback(data);
          }
        },
        error => {
          console.error(error);
        },
      );

    },
  },
};